import React from 'react'
import Toggle from 'react-toggle'
// import ReactBootstrapSlider from 'react-bootstrap-slider'
// import Slider from 'rc-slider'
// import 'rc-slider/assets/index.css'
import { isInternalUser } from '../../helpers/constants'
import CustomButton from '../../CustomButton'
import CustomReactBootstrapSlider from '../../CustomReactBootstrapSlider'
import { timezoneDBOptions } from '../../helpers/constants'
import SelectionEventVideoType from './SelectionEventVideoType'

// import 'bootstrap-slider/dist/css/bootstrap-slider.css'

const FleetConfigurationGeneral = (props) => {
	const { settingsType, configuration, configurationdv6, fillFormBoolean, fillFormInt, fillForm, user, event_types, setConfiguration } = props
	const isDV6 = settingsType === 'dv6'
	const isDV4 = settingsType === 'dv4'
	const currentConfig = isDV6 ? configurationdv6 : configuration

	const setEventTypes = (argTypeValue) => {
		setConfiguration({ event_types: { ...event_types, ...argTypeValue } })
	}

	return (
		<main className="general-settings main-content-block">
			<h3 className="category-title">General Settings</h3>
			<div className="category-subheader">
				These settings are for various functions of the camera such as chime volume, time zone, parking mode duration, and other similar settings.
			</div>

			<div className="settings-card">
				<div className="card-header">
					Overlay Speed on Video
					<Toggle
						name="Overlay.ShowSpeed"
						checked={currentConfig.Overlay.ShowSpeed}
						icons={false}
						onChange={(e) => fillFormBoolean(e, isDV6)}
					/>
				</div>
				{/* anumation of card-body content displaing with delay 0.5s */}
				<div className="card-body">
					<p>If enabled, this option will display the vehicle speed at the bottom right corner in the video.</p>
					<div className={`${!currentConfig.Overlay.ShowSpeed ? 'hide' : ''}`}>
						<div className="toggle-wrapper button-group">
							<label className="settings-title">Speed Unit</label>
							<div className="toggle-buttons">
								<CustomButton
									variant='toggle'
									active={currentConfig.System.SpeedUnit === 1}
									value="1"
									name="System.SpeedUnit"
									// onClick={(e) => fillFormThreshold(e, "both")}
									onClick={(e) => fillFormInt(e, isDV6)}
								>
									MPH
								</CustomButton>
								<CustomButton
									variant='toggle'
									active={currentConfig.System.SpeedUnit === 2}
									value="2"
									name="System.SpeedUnit"
									// onClick={(e) => fillFormThreshold(e, "both")}
									onClick={(e) => fillFormInt(e, isDV6)}
								>
									KPH
								</CustomButton>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* DV6 settings only */}
			{isDV6 &&
				<>
					<div className="settings-card">
						<div className="card-header">
							Camera Volume
							<div className="slider-wrapper header-slider mr-10">
								<div className="slider-minmax-value min-value">
									0 %
								</div>
								<CustomReactBootstrapSlider
									name="System.Chimes.Volume"
									max={100}
									min={0}
									step={1}
									tooltip="show"
									value={currentConfig.System.Chimes.Volume}
									formatter={(v) => `${v}%`}
									change={(e) => fillFormInt(e, isDV6, 'System.Chimes.Volume')}
								// disabled="disabled"
								// ticks={[60, 70, 80, 90, 100]}
								/>
								<div className="slider-minmax-value max-value">
									100%
								</div>
							</div>
						</div>
						<div className="card-body">
							<p>Controls the volume of sounds that come from the camera.</p>
						</div>
					</div>
				</>
			}

			{/* DV4 settings only */}
			{isDV4 &&
				<div className="settings-card">
					<div className="card-header">
						Camera Audible Chimes
						<Toggle
							name="Event.BuzzerEnabled"
							checked={currentConfig.Event.BuzzerEnabled}
							icons={false}
							onChange={(e) => fillFormBoolean(e, isDV6)}
						/>
					</div>
					<div className="card-body">
						<p>If enabled, the camera will emit audible chimes when an event occurs.</p>
					</div>
				</div>
			}

			<div className="settings-card">
				<div className="card-header">
					Parking Mode
					<Toggle
						name="ParkingMode"
						checked={currentConfig.General.IgnitionShutdownDelay > 0}
						icons={false}
						onChange={(e) => fillForm(e, isDV6)}
					/>
				</div>
				<div className="card-body">
					<p>If enabled, the camera will continue to record after vehicle ignition is off at a reduced quality setting.</p>
					{/* anumation of card-body content displaing with delay 0.5s */}
					<div className={`${currentConfig.General.IgnitionShutdownDelay === 0 ? 'hide' : ''}`}>
						<div className="toggle-wrapper button-group">
							<label className="settings-title">Parking Mode Duration:</label>
							<select
								placeholder="select"
								className="form-control rosco-select"
								name="General.IgnitionShutdownDelay"
								onChange={(e) => fillFormInt(e, isDV6)}
								value={currentConfig.General.IgnitionShutdownDelay}
							>
								<option value="0">Disabled</option>
								<option value="30">30 Minutes</option>
								<option value="60">1 Hour </option>
								<option value="90">1 1/2 Hour</option>
								<option value="120">2 Hours</option>
								<option value="180">3 Hours</option>
								<option value="240">4 Hours</option>
								<option value="360">6 Hours</option>
								<option value="480">8 Hours</option>
								<option value="600">10 Hours</option>
								<option value="720">12 Hours</option>
								<option value="1440">24 Hours</option>
								{/* <option value="2880">48 Hour</option>
								<option value="4320">72 Hour</option> */}
							</select>
						</div>
					</div>
				</div>
			</div>

			<div className="settings-card">
				<div className="card-header">
					Timezone
					<div>
						<select
							placeholder="select"
							className="form-control rosco-select"
							name="General.TimeZone"
							value={currentConfig.General.TimeZone}
							onChange={(e) => fillForm(e, isDV6)}
						>
							{timezoneDBOptions()}
						</select>
					</div>
				</div>
				<div className="card-body">
					<p>The timezone setting for the camera. The camera will always report times in this timezone regardless of location of camera.</p>
				</div>
			</div>

			{/* DV4 settings only */}
			{isDV4 &&
				<div className="settings-card">
					<div className="card-header">
						LCD Backlight
					</div>
					<div className="card-body">
						<p>Controls the brightness of the LCD panel during Day and Night modes.</p>

						<div className="toggle-wrapper button-group">
							<label className="settings-title">Day Mode</label>
							<div className="slider-wrapper header-slider mr-10">
								<div className="slider-minmax-value min-value">
									0
								</div>
								<CustomReactBootstrapSlider
									name="System.BacklightHigh"
									max={1000}
									min={0}
									step={100}
									tooltip="show"
									value={currentConfig.System.BacklightHigh || 0}
									change={(e) => fillFormInt(e, isDV6, 'System.BacklightHigh')}
								// ticks={[60, 70, 80, 90, 100]}
								/>
								<div className="slider-minmax-value max-value">
									1000
								</div>
							</div>
						</div>

						<div className="toggle-wrapper button-group">
							<label className="settings-title">Night Mode</label>
							<div className="slider-wrapper header-slider mr-10">
								<div className="slider-minmax-value min-value">
									0
								</div>
								<CustomReactBootstrapSlider
									name="System.BacklightLow"
									max={1000}
									min={0}
									step={100}
									tooltip="show"
									value={currentConfig.System.BacklightHigh || 0}
									change={(e) => fillFormInt(e, isDV6, 'System.BacklightLow')}
								// ticks={[60, 70, 80, 90, 100]}
								/>
								<div className="slider-minmax-value max-value">
									1000
								</div>
							</div>
						</div>
					</div>
				</div>
			}

			{/* DV6 settings only */}
			{isDV6 &&
				<div className="settings-card">
					<div className="card-header">
						Wake-Up / Sleep Mode
						<Toggle
							name="Servers.SleepMode.Enabled"
							checked={currentConfig.Servers.SleepMode.Enabled}
							icons={false}
							onChange={(e) => fillFormBoolean(e, isDV6)}
						/>
					</div>
					<div className="card-body">
						<p>
							If enabled, the camera will remain connected by entering sleep mode when powering down instead of going offline. This will allow the camera to be awakened at any point when in sleep mode to retrieve video, live stream, or make changes.<br/><br/>
							<a href="https://support.roscovision.com/knowledge/sleep-mode-feature-guide" target="_blank">Learn More</a>
						</p>
					</div>
				</div>
			}
		</main>
	)
}

export default FleetConfigurationGeneral;
